<mat-sidenav-container class="sidenav-container" *ngIf="$auth.loaded | async">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="!(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async)">
    <!-- <mat-toolbar> -->
      <ng-container *ngIf="$auth.participant | async">
        <div  class="participant-card" [routerLink]="'my-account'">
          <div class="participant-card-image"><img [src]="($auth.participant | async)!.image"></div>
          <div class="participant-card-name">{{($auth.participant | async)!.name}}</div>
          <div class="participant-card-title">{{($auth.participant | async)!.title}}</div>
          <div class="participant-card-link">{{($auth.participant | async)!.contactLocation}}</div>
        </div>
      </ng-container>

    <!-- </mat-toolbar> -->
    <mat-nav-list>
      <mat-divider></mat-divider>
      <ng-container *ngIf="($auth.participant | async)!.id != 'anonymous'">
        <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'projects'" ><mat-icon>home</mat-icon> NASLOVNICA</a>
        <mat-divider></mat-divider>
        <p>All projects:</p>
        <a mat-list-item *ngFor="let project of projects" [ngClass]="{'active' : ($auth.selectedProject | async) == project}"  [routerLink]="'subjects/' + project.id" (click)="$auth.selectedProject.next(project); $auth.selectedSubject.next(undefined)"><mat-icon>school</mat-icon> {{project.name}}</a>
        <mat-divider></mat-divider>
      </ng-container>

      <ng-container *ngIf="($auth.participant | async)!.id == 'anonymous'">
        <p>Project: {{($auth.selectedProject | async).name}}</p>
      </ng-container>
      <ng-container >
        <a mat-list-item [routerLinkActive]="'active'" href="../../assets/eSleep_dHealth-Curriculum-1.pdf"  *ngIf=" ($auth.selectedProject | async) && ($auth.selectedProject | async).id == 'avGKV8FFogtT6JfuOHuu'"><mat-icon>school</mat-icon> CURRICULUM</a>

        <p>Subjects:</p>
        <a mat-list-item *ngFor="let subject of subjects" [ngClass]="{'active' : ($auth.selectedSubject | async) == subject}"   [routerLink]="'subject/'  + ($auth.selectedProject | async)!.id + '/' + subject.id" (click)="$auth.selectedSubject.next(subject)"><mat-icon>history_edu</mat-icon> {{subject.name}}</a>
        <mat-divider></mat-divider>
      </ng-container>
     

      
     
      <ng-container *ngIf="($auth.selectedProject | async)">
        <p>Project directory:</p>
        <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'programmes/' + ($auth.selectedProject | async)!.id" (click)="$auth.selectedSubject.next(undefined)" *ngIf="($auth.selectedProject | async) && ($auth.selectedProject | async).id != 'avGKV8FFogtT6JfuOHuu'"><mat-icon>fork_right</mat-icon> PROGRAMMES</a>
        <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'subjects/' + ($auth.selectedProject | async)!.id" (click)="$auth.selectedSubject.next(undefined)"><mat-icon>history_edu</mat-icon> HOME</a>
        <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'classrooms/' + ($auth.selectedProject | async)!.id" (click)="$auth.selectedSubject.next(undefined)" *ngIf="(isHandset$ | async) && ($auth.selectedProject | async) && ($auth.selectedProject | async).id != 'avGKV8FFogtT6JfuOHuu'"><mat-icon>meeting_room</mat-icon> CLASSROOMS</a>
        <!-- <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'calendar/' + ($auth.selectedProject | async)!.id" (click)="$auth.selectedSubject.next(undefined)"><mat-icon>calendar_month</mat-icon> EVENTS</a> -->
        <!-- <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'participants/' + ($auth.selectedProject | async)!.id" (click)="$auth.selectedSubject.next(undefined)"><mat-icon>people</mat-icon> PARTICIPANTS</a> -->
        <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'about'"  *ngIf="($auth.selectedProject | async)!.id == '9l6vyS0TZMP9QceFzMvn'"><mat-icon>question_mark</mat-icon> PROJECT INFORMATION</a>

        


      </ng-container>
      <mat-divider></mat-divider>
      
      <ng-container *ngIf="($auth.participant | async)!.id != 'anonymous'">
        <p>Moj Indeks:</p>
        <!-- <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'my-grades'"><mat-icon>grade</mat-icon> OCJENE</a> -->
        <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'my-calendar'"  (click)="$auth.selectedSubject.next(undefined)"><mat-icon>event</mat-icon> MOJ RASPORED</a>
        <!-- <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'my-attendance'"><mat-icon>free_cancellation</mat-icon> PRISUTNOST</a> -->
        <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'my-account'" (click)="$auth.selectedSubject.next(undefined)"><mat-icon>person</mat-icon> MOJ PROFIL</a>
        <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'my-notifications'" (click)="$auth.selectedSubject.next(undefined)"><mat-icon>notifications</mat-icon>MOJE OBAVIJESTI</a>
        <a mat-list-item [routerLinkActive]="'active'"  [routerLink]="'aboutus'" (click)="$auth.selectedSubject.next(undefined)"><mat-icon>question_mark</mat-icon>O NAMA</a>
        <a mat-list-item (click)="logout()"><mat-icon>logout</mat-icon> ODJAVA</a>
        <mat-divider></mat-divider>
      </ng-container>
      <div class="signature" [hidden]="($auth.selectedProject | async) && ($auth.selectedProject | async).id == 'avGKV8FFogtT6JfuOHuu'">Izradu mrežne platforme sufinancirala je Europska unija iz Operativnog programa „Učinkoviti ljudski potencijali 2014.-2020.“</div>
      
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary" class="navbar">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span class="educa-logo"></span>
      <span >EDUCA</span>
      <span class="eu"></span>
      <span class="eu-text">Europska unija<br>"Zajedno do fondova EU"</span>

    </mat-toolbar>
    <div class="lrc">
      <div class="signature2" *ngIf="(isHandset$ | async) && ($auth.selectedProject | async) && ($auth.selectedProject | async).id != 'avGKV8FFogtT6JfuOHuu'">Izradu mrežne platforme sufinancirala je Europska unija iz Operativnog programa „Učinkoviti ljudski potencijali 2014.-2020.“</div>
      
      <div class="left">
        <mat-card style="margin-bottom: 20px" *ngIf="!(isHandset$ | async) && ($auth.selectedProject | async).id != 'avGKV8FFogtT6JfuOHuu'">
          <div class="lenta"></div>
        </mat-card>
        <mat-card style="margin-bottom: 20px" *ngIf="($auth.selectedProject | async).id == 'avGKV8FFogtT6JfuOHuu'">
          <div class="lenta esleep"></div>
          <p style="text-align: center; font-weight: bold;">Digital literacy enables up-to-date
            sleep medicine in inclusive healthcare</p>
        </mat-card>
        <router-outlet #outlet="outlet"></router-outlet>
        
      </div>
      <div class="right" *ngIf="$router.url != '/app/about'">
        <mat-card style="margin-bottom: 20px" *ngIf="(isHandset$ | async) && ($auth.selectedProject | async) && ($auth.selectedProject | async).id != 'avGKV8FFogtT6JfuOHuu'">
          <div class="lenta"></div>
        </mat-card>

        <mat-card style="margin-bottom: 20px; padding: 4px; overflow: hidden;" *ngIf=" ($auth.selectedProject | async) && ($auth.selectedProject | async).id == 'avGKV8FFogtT6JfuOHuu'">
          <div>
            <iframe width="100%" height="250px" src="https://www.youtube.com/embed/pMzEO5Vi4do" title="eSleep_dHealth" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        
        </mat-card>

         <mat-card *ngIf="($auth.selectedProject | async).id != 'avGKV8FFogtT6JfuOHuu'">

          <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; margin-bottom: 10px;"> 
           
            <button mat-mini-fab color="accent" mwlCalendarPreviousView [view]="'month'" [(viewDate)]="viewDate">
              <mat-icon >chevron_left</mat-icon>
            </button>
           
           
            <h3 style="text-align: center; margin-bottom: 0px; margin-left: 10px; margin-right: 10px; font-weight: bold; font-size: 16px;">{{ viewDate | calendarDate:('month' + 'ViewTitle'):'hr' }}</h3>
            <button mat-mini-fab color="accent" mwlCalendarNextView [view]="'month'" [(viewDate)]="viewDate">
              <mat-icon >chevron_right</mat-icon>
            </button>
          </div>

          <mwl-calendar-month-view
            [events]="calendarEvents"
            [viewDate]="viewDate"
            (beforeViewRender)="updateEvents()"
            (dayClicked)="toggleDayHighlight($event, true)"
            [activeDay]="selectedDay"
            [activeDayIsOpen]="!!selectedDay"
            (eventClicked)="link($event.event)"
          ></mwl-calendar-month-view>

          <div class="event" style="position: relative" [ngClass]="{'lecture' : event.type == 'lecture', 'workshop' : event.type == 'workshop','general' : event.type == 'general','competition' : event.type == 'competition','past' : event.start.toDate() < now}" *ngFor="let event of events" (click)="link(event)">
            <div style="font-weight: bold;">{{event.name}}</div>
            <div style="position: absolute; top: 8px; right: 8px;">{{event.subjectName}}</div>
            <div>{{event.start.toDate() | date:'dd/MM/YYYY'}}  </div>
            <span style="text-align:end; position:absolute; bottom: 8px; right: 8px;">{{event.start.toDate() | date:'HH:mm'}} - {{event.end.toDate() | date:'HH:mm'}}</span>
          </div>
        </mat-card>
        <p style="text-align: center;">You can learn more about the project here: <a href="https://esleepdhealth.nethub.hr/about/">https://esleepdhealth.nethub.hr/about/</a></p>
        <!-- <mat-card class="notifications">
          <p style="font-size: 18px; margin-left: 0px;">Moje obavijesti:</p>
          <p *ngIf="!$auth.notifications.length">Nemate ni jednu obavijest</p>

          <div class="notification" *ngFor="let notification of $auth.notifications" [ngClass]="{'notification-seen':notification.seen}" (click)="open(notification)">
              <div class="notification-text">{{notification.text}}</div>
              <div class="notification-time">{{notification.time.toDate() | date:'short'}}</div>
              <button class="notification-button" mat-raised-button color="accent"><mat-icon>play_arrow</mat-icon></button>
          </div>
        </mat-card> -->
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
