<form class="container" #myForm="ngForm">
    <mat-form-field appearance="outline">
        <mat-label>Naziv projekta</mat-label>
        <input matInput [(ngModel)]="project.name" type="text" required name="name"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Kratki opis</mat-label>
        <input matInput [(ngModel)]="project.description" type="text" required name="description"/>
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Naslovna slika</mat-label>
        <input matInput type="text" name="image" required [(ngModel)]="project.image" disabled (click)="fileUpload.click()">
        <button mat-mini-fab matSuffix color="accent" (click)="fileUpload.click()"><mat-icon>attachment</mat-icon></button>
        <input type="file" class="file-input" style="display: none;"
       (change)="onFileSelected($event)" #fileUpload>
    </mat-form-field>

    <mat-checkbox  [(ngModel)]="project.public" name="public" class="example-margin">Javni pristup</mat-checkbox>

    <button mat-raised-button color="accent" style="margin-top: 40px;" (click)="save()">Spremi promjene</button>
</form>
