<app-banner></app-banner>

<mat-card class="health_banner" style="margin-bottom: 40px;" *ngIf="($auth.selectedProject | async) && ($auth.selectedProject | async).id == 'avGKV8FFogtT6JfuOHuu'">

</mat-card >
<div class=" subjects">
    <mat-card class="card" *ngFor="let subject of subjects" (click)="details(subject)">
            <img mat-card-image class="card-image" [src]="  subject.image" alt="">
                <h3 class="card-title">{{  subject.name}}</h3>
                <p style="margin-bottom: 4px;"></p>
                <p>{{  subject.description}}</p>
                <mat-divider></mat-divider>
                <div style="display: grid; grid-template-columns: auto 1fr; padding-top: 12px;">
                    <mat-icon>people</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{ subject.participantsIds.length}} PARTICIPANTS</p>
                    <mat-icon>description</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{ subject.assetsIds.length}} MATERIALS</p>
                    <mat-icon>event</mat-icon>
                    <p style="margin-left: 8px; font-weight: 500">{{ subject.EventsIds.length}} EVENTS</p>
                </div>
                <mat-divider></mat-divider>
            <div class="card-buttons">
                <button mat-raised-button color="accent" ><mat-icon>history_edu</mat-icon> DETAILS</button>
                <button class="short-card-button" *ngIf="($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer'" (click)="edit(  subject)" mat-raised-button color="accent"><mat-icon>edit</mat-icon></button>
                <button class="short-card-button" *ngIf="($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer'" (click)="delete(  subject)" mat-raised-button color="accent"><mat-icon>delete</mat-icon></button>
            </div>
    </mat-card>

    <mat-card class="card"  (click)="openCurr()">
        <img mat-card-image class="card-image" [src]="'https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/ehealth%20logo%20padded.png?alt=media&token=9198a7c6-a080-41bc-ae99-54356623c274'" alt="">
            <h3 class="card-title">CURRICULUM</h3>
            <p style="margin-bottom: 4px;"></p>
            <p></p>
            <mat-divider></mat-divider>
            <!-- <div style="display: grid; grid-template-columns: auto 1fr; padding-top: 12px;">
                <mat-icon>people</mat-icon>
                <p style="margin-left: 8px; font-weight: 500">{{ subject.participantsIds.length}} PARTICIPANTS</p>
                <mat-icon>description</mat-icon>
                <p style="margin-left: 8px; font-weight: 500">{{ subject.assetsIds.length}} MATERIALS</p>
                <mat-icon>event</mat-icon>
                <p style="margin-left: 8px; font-weight: 500">{{ subject.EventsIds.length}} EVENTS</p>
            </div> -->
            <mat-divider></mat-divider>
        <div class="card-buttons">
            <button mat-raised-button color="accent" ><mat-icon>history_edu</mat-icon> DETAILS</button>
            <!-- <button class="short-card-button" *ngIf="($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer'"  mat-raised-button color="accent"><mat-icon>edit</mat-icon></button> -->
            <!-- <button class="short-card-button" *ngIf="($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer'" (click)="delete(  subject)" mat-raised-button color="accent"><mat-icon>delete</mat-icon></button> -->
        </div>
</mat-card>
    <button *ngIf="($auth.participant | async)!.role == 'admin' || ($auth.participant | async)!.role == 'organizer'" mat-raised-button color="accent" style="height: 72px; width: 72px;" (click)="create()"><mat-icon style="scale: 3;">add</mat-icon></button>
</div>

<mat-card class="health_banner_2" style="margin-bottom: 40px;" *ngIf="($auth.selectedProject | async) && ($auth.selectedProject | async).id == 'avGKV8FFogtT6JfuOHuu'">

</mat-card >


