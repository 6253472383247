import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { Asset, Classroom, Event, Message, Participant, Subject } from 'models';
import { combineLatestWith, Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { DatabaseService } from '../database.service';
import { LoaderService } from '../loader.service';
import { SubjectsEditComponent } from '../subjects/subjects-edit/subjects-edit.component';
import { SubjectContentComponent } from './subject-content/subject-content.component';
import { SubjectParticipantsComponent } from './subject-participants/subject-participants.component';
import { NgForm } from '@angular/forms';
import { ChatComponent } from '../chat/chat.component';
import { SubjectPasteventsComponent } from './subject-pastevents/subject-pastevents.component';

@Component({
  selector: 'app-subject',
  templateUrl: './subject.component.html',
  styleUrls: ['./subject.component.css']
})
export class SubjectComponent implements OnInit, OnDestroy {
    constructor(
      public $route : ActivatedRoute,
      public $dialog: MatDialog,
      public $router : Router,
      public $auth : AuthService,
      public $database : DatabaseService,
      public $loader: LoaderService,
      public $functions : AngularFireFunctions
    ){
      // this.subject = this.$data.subjects.find(s => s.id == $route.snapshot.params['subjectId']) as Subject
      // this.participants = this.$data.participants.filter(p => this.subject.participantsIds.includes(p.id));
      // this.classroom = this.$data.classrooms.find(c => c.id == this.subject.classroomId) as Classroom
      
      // this.event = this.$data.events.filter(e => e.subjectId == this.subject.id).sort((a,b) => a.start > b.start ? 1 : -1)[0]
      // if(this.event){
      //   this.event.classroom = this.$data.classrooms.find(c => c.id == this.event?.classroomId)
      // console.log(this.event)
      // if(this.event.start < new Date() && this.event.end > new Date()) this.occuring = true
    // }
    }

    subs : Subscription[] = []
    ngOnDestroy(): void {
      this.subs.forEach(s => s.unsubscribe())
    }

    ngOnInit(): void {
      this.subs.push(this.$auth.selectedSubject.subscribe(sub => {
        this.subject = sub;
        this.classroom = this.$database.classrooms.getValue().find(c => c.id == sub.classroomId)
      }))


      this.subs.push(this.$database.subjects.pipe(combineLatestWith(this.$database.participants, this.$database.messages))
        .subscribe(([subjects, participants, messages]) => {
          // this.subject = subjects.find(s => s.id == this.$route.snapshot.params['subjectId'])
        
          if(this.subject && participants) this.participants = participants.filter(p => this.subject.participantsIds.includes(p.id))
        
          this.event = this.$database.events.getValue().filter(e => e.subjectId == this.subject.id && e.end.toDate() > new Date())[0]
       
          this.messages = messages.filter(m => m.recipientId == this.$auth.selectedSubject.getValue().id).sort((a,b) => a.time.toDate() > b.time.toDate() ? 1 : -1)
          this.messages.forEach(m => {m.participant = participants.find(p => p.id == m.participant.id); if(m.participant.status == 'online' && (!m.participant.lastActive || m.participant.lastActive.toDate() < new Date())) m.participant.status = 'away';});
          this.messages.filter(m => !m.seens.includes(this.$auth.participant.getValue().id)).forEach(m => {
            console.log("message")
            this.$database.seeMessage(m.id, this.$auth.participant.getValue().id)
          });
          //@ts-ignore
          this.messages.forEach(m => m.seens = m.seens.map(sid => participants.find(p => p.id == sid).name))

          console.log(this.messages)
        }))

        

        this.subs.push(this.$database.assets.subscribe(assets => {
          this.assets = assets.filter(a => a.subjectId == this.subject.id)
        }))


        this.loaded = true;
    }

    public loaded = false;
    public subject : Subject
    public participants : Participant[]
    public classroom : Classroom
    public event?: Event
    public occuring = false;
    public assets : Asset[] = [];
    public messages: Message[] = [];

    openSubject(){
      this.$dialog.open(SubjectsEditComponent, { 
        data: {subject: this.subject},
        minWidth: '600px'
      })
    }

    openClassroom(){
      if(this.classroom.type == 'virtual'){ 
        this.$loader.open();
        this.$functions.httpsCallable('addParticipantToMeeting')({
          email: this.$auth.participant.getValue().email,
          id: this.classroom.linkId
        }).toPromise().then(res => {
          this.$loader.close();
          window.open(this.classroom.link, '_blank');
        }).catch(error => {
          console.log(error)
        })
      } else {
        window.open('http://maps.google.com/?q=' + encodeURI(this.classroom.location), '_blank')
      }
    }

    openAsset(asset : any){

      if(this.$auth.selectedProject.getValue().id == 'avGKV8FFogtT6JfuOHuu'){
        window.open(asset.link,'_blank');
        return
      }


      
      if(['doc','sheet','slide','form'].includes(asset.type)){
        this.$loader.open()
      this.$functions.httpsCallable('setAssetPermissions')({id:asset.google, email: this.$auth.participant.getValue().email, role: this.$auth.participant.getValue().role == 'participant' ? 'reader' : 'writer'}).toPromise().then(() => {
        this.$loader.close();
        window.open(asset.link,'_blank');
      })}
      else {
        window.open(asset.link,'_blank');
      }
    }

    openCalendar(){
      this.$auth.selectedSubject.next(this.subject)
      this.$router.navigate(['app/calendar', this.subject.id, this.subject.projectId])
    }

    openContent(){
      this.$dialog.open(SubjectContentComponent, { data: {subject : this.subject} , width: '600px', maxHeight: '90vh'})
    }

    openParticipants(){
      this.$dialog.open(SubjectParticipantsComponent, { data: {subject : this.subject}, width: '600px'},)
    }

    messageText = '';
    sendMessage(){
      this.$loader.open()
      this.$database.createMessage(this.messageText, this.$auth.participant.getValue(), this.subject.id, true).then(() => {
        this.$loader.close();
        this.$loader.toast('Poruka uspješno poslana!')
      this.messageText = '';
      })
    }

    openMeeting(){
      this.$loader.open()
    }

    openChat(p = undefined){
      this.$dialog.open(ChatComponent, {
        data: {
          recipientId : p.id
        }, width: '600px', maxHeight: '90vh'
      })
    }

    openPastEvents(){
      this.$dialog.open(SubjectPasteventsComponent, {
        data: {
          subjectId : this.subject.id,
          projectId: this.subject.projectId
        }, width: '600px', maxHeight: '90vh'
      })
    }
}
