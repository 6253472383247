import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { NavigationEnd, Router } from '@angular/router';
import { Asset, Classroom, Participant, Programme, Project, Subject, Event, Notification, Message } from 'models';
import { BehaviorSubject, finalize, isObservable } from 'rxjs'
import { arrayUnion, arrayRemove } from '@angular/fire/firestore';
import { CalendarEvent, CalendarEventAction } from 'angular-calendar';
import { AngularFireStorage } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(
    public $auth: AngularFireAuth,
    public $firestore : AngularFirestore,
    public $router : Router,
    public $zone : NgZone,
    public $functions : AngularFireFunctions,
    public $storage : AngularFireStorage
  ) {
    this.load();
  }

  public loaded = false;
  public loading = false;
  public loadedSubject = new BehaviorSubject<boolean>(false);
  public load(){
    return new Promise((resolve, reject) => {
      if(this.loaded) {
        resolve(true);
        return;
      }
      if(!this.loading) {
        this.loading = true;
        console.log("starting database..")
        Promise.all([
        new Promise((resolve, reject) => {this.$firestore.collection(`projects`).valueChanges().subscribe(res => { this.projects.next(res.sort((a:Project,b:Project) => a.name > b.name ? 1 : -1) as Project[]); resolve(true)})}),
        new Promise((resolve, reject) => {this.$firestore.collection(`programmes`).valueChanges().subscribe(res => { this.programmes.next(res.sort((a:Programme,b:Programme) => a.name > b.name ? 1 : -1) as Programme[]); resolve(true)})}),
        new Promise((resolve, reject) => {this.$firestore.collection(`participants`).valueChanges().subscribe(res => { this.participants.next(res.sort((a:Participant,b:Participant) => a.name > b.name ? 1 : -1).map((p:Participant) => {if(p.status == 'online' && (!p.lastActive || p.lastActive.toDate() < new Date())) p.status = 'away'; return p}) as Participant[]); resolve(true)})}),
        new Promise((resolve, reject) => {this.$firestore.collection(`classrooms`).valueChanges().subscribe(res => { this.classrooms.next(res.sort((a:Classroom,b:Classroom) => a.name > b.name ? 1 : -1) as Classroom[]); resolve(true)})}),
        new Promise((resolve, reject) => {this.$firestore.collection(`subjects`).valueChanges().subscribe(res => { this.subjects.next(res.sort((a:Subject,b:Subject) => a.name > b.name ? 1 : -1) as Subject[]); resolve(true)})}),
        new Promise((resolve, reject) => {this.$firestore.collection(`assets`).valueChanges().subscribe(res => { this.assets.next(res as Asset[]); resolve(true)})}),
        new Promise((resolve, reject) => {this.$firestore.collection(`events`).valueChanges().subscribe(res => { this.events.next(res as Event[]); resolve(true)})}),
        new Promise((resolve, reject) => {this.$firestore.collection(`notifications`).valueChanges().subscribe(res => { this.notifications.next(res.sort((a:Notification,b:Notification) => a.time.toDate() > b.time.toDate() ? -1 : 1) as Notification[]); resolve(true)})}),
        new Promise((resolve, reject) => {this.$firestore.collection(`messages`).valueChanges().subscribe(res => { this.messages.next(res.sort((a:Message,b:Message) => a.time.toDate() > b.time.toDate() ? 1 : -1) as Message[]); resolve(true)})}),
        ]).then(() => {
          this.loadedSubject.next(true)
          resolve(true);
        })
      } else {
        this.loadedSubject.subscribe(loaded => { if(loaded) resolve(true)})
      }
    })
  }
  
  public projects = new BehaviorSubject<Project[]>([]);
  public programmes = new BehaviorSubject<Programme[]>([]);
  public participants = new BehaviorSubject<Participant[]>([]);
  public classrooms = new BehaviorSubject<Classroom[]>([]);
  public subjects = new BehaviorSubject<Subject[]>([]);
  public assets = new BehaviorSubject<Asset[]>([]);
  public events = new BehaviorSubject<Event[]>([]);
  public notifications = new BehaviorSubject<Notification[]>([]);
  public messages = new BehaviorSubject<Message[]>([]);

  
  public eventToCalendarEvent(e){
    function eventToColor(type: string, date){
      return date <= new Date() ? '#50505050' : 
      type == 'lecture' ? '#00a0e3' : 
      type == 'workshop' ? '#ef7f1a' : 
      type == 'competition' ? '#57b039' : '#eec53e';
    }
    return {projectId:e.projectId,subjectId:e.subjectId, start: e.start.toDate(), end: e.end.toDate(), title: e.name, color: {'primary' : eventToColor(e.type, e.start.toDate()), 'secondary': eventToColor(e.type, e.start.toDate()), secondaryText: '#ffffff'}, id: e.id} as CalendarEvent
  }


  public createProject(name: string, image: string | undefined, description: string, ispublic: boolean){
    return new Promise((resolve, reject) => {
      this.$firestore.collection('projects').add({
        name: name,
        image: image ? image : "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: description,
        public: ispublic,
        programmesIds: [],
        subjectsIds: [],
        participantsIds: [],
        messagesIds: []
      }).then((res) => {
        this.$firestore.doc(`projects/${res.id}`).update({id:res.id}).then(resolve).catch(reject)
      }).catch(reject)
    })
  }

  public updateProject(id: string, name: string, image: string | undefined, description: string, ispublic : boolean){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`projects/${id}`).update({
        name: name,
        public: ispublic,
        image: image ? image : "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: description,
      }).then(resolve).catch(reject)
    })
  }

  public deleteProject(id : string){
    return new Promise((resolve, reject) => {
      let promises = [] as Promise<any>[]
      promises.concat(this.subjects.getValue().filter(s => s.projectId == id).map(s => this.$firestore.doc(`subjects/${s.id}`).delete()))
      promises.push(this.$firestore.doc(`projects/${id}`).delete())
      Promise.all(promises).then(resolve).catch(reject)
    })
  }

  public createProgramme(name: string, image: string | undefined, description: string, projectId: string, ){
    return new Promise((resolve, reject) => {
      this.$firestore.collection('programmes').add({
        name: name,
        image: image ? image : "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: description,
        projectId: projectId,
        subjectsIds: [],
        participantsIds: [],
        messagesIds: []
      }).then((res) => {
        this.$firestore.doc(`projects/${projectId}`).update({
          programmesIds: arrayUnion(res.id)
        })
        this.$firestore.doc(`programmes/${res.id}`).update({id:res.id}).then(resolve).catch(reject)
      }).catch(reject)
    })
  }

  public updateProgramme(id: string, name: string, image: string | undefined, description: string){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`programmes/${id}`).update({
        name: name,
        image: image ? image : "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: description,
      }).then(resolve).catch(reject)
    })
  }

  public deleteProgramme(programme : Programme){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`projects/${programme.projectId}`).update({programmesIds: arrayRemove(programme.id)})
      return this.$firestore.doc(`programmes/${programme.id}`).delete();
    })
  }

  public createParticipant(email : string, password :string, name: string, image: string | undefined, description: string, projectId: string, education: string, role: string, title: string, contactEmail: string, contactLink:string, contactLocation:string, contactPhone: string){
    return new Promise((resolve, reject) => {
      this.$functions.httpsCallable('createAccount')({email, password}).toPromise().then(res => {
        this.$firestore.doc(`projects/${projectId}`).update({
          participantsIds: arrayUnion(res)
        })

        this.$firestore.doc(`participants/${res}`).set({
          id: res,
          projectId: projectId,
          email: email,
          name: name,
          image: image ? image : "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
          description: description,
          title: title,
          role: role,
          education: education,
          contactEmail: contactEmail,
          contactPhone: contactPhone,
          contactLink: contactLink,
          contactLocation: contactLocation
        }).then(() => {
          this.$functions.httpsCallable('sendEmail')({
            recipient: email,
            subject: 'Stvoren korisnički račun na Educa platformi!',
            message: `Administratori Educa platforme stvorili su ti korisnički račun s idućim podatcima:\n\nEMAIL: ${email}\nPASSWORD: ${password}`
          }).toPromise().then(resolve).catch(reject)
        })
      }).catch(reject)
    })
  }

  public updateParticipant(id: string, email : string, password :string, name: string, image: string | undefined, description: string, projectId: string, education: string, role: string, title: string, contactEmail: string, contactLink:string, contactLocation:string, contactPhone: string){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`participants/${id}`).update({
        name: name,
        image: image ? image : "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: description,
        title: title,
        role: role,
        education: education,
        contactEmail: contactEmail,
        contactPhone: contactPhone,
        contactLink: contactLink,
        contactLocation: contactLocation
      }).then(resolve).catch(reject)
    })
  }

  public deleteParticipant(participant : Participant){
    return new Promise((resolve, reject) => {
      console.log("deleting participant")
      console.log(participant)
      this.$functions.httpsCallable('deleteAccount')({id: participant.id})
      this.$firestore.doc(`projects/${participant.projectId}`).update({participantsIds: arrayRemove(participant.id)})
      return this.$firestore.doc(`participants/${participant.id}`).delete();
    })
  }

  public createClassroom(name, image, description, type, projectId, location){
    return new Promise((resolve, reject) => {
      this.$firestore.collection('classrooms').add({
        name: name,
        image: image ? image : "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: description,
        type: type,
        subjectsIds: [],
        link: 'https://meet.google.com/pnj-szfu-mxi',
        projectId: projectId,
        eventsIds: [],
        messagesIds: [],
        location
      }).then((res) => {
        this.$firestore.doc(`classrooms/${res.id}`).update({id:res.id}).then(
          (() => {
            if(type == 'virtual')
            this.$functions.httpsCallable('createPermanentMeet')({classroomName: name}).toPromise().then(data => {
              this.$firestore.doc(`classrooms/${res.id}`).update({link: 'https://meet.google.com/' + data.meetingId, linkId: data.eventId}).then(resolve).catch(reject)
            })
            else
              resolve(true);
          })
        ).catch(reject)
        
      }).catch(reject)
    })
  }

  public updateClassroom(id, name, image, description, type, location){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`classrooms/${id}`).update({
        name: name,
        image: image ? image : "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: description,
        type: type,
        location
      }).then(resolve).catch(reject)
    })
  }

  public deleteClassroom(id){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`classrooms/${id}`).delete().then(resolve).catch(reject)
    })
  }

  public createSubject(name, image, description, projectId, programmeId, classroomId, board){
    return new Promise((resolve, reject) => {
      this.$firestore.collection(`subjects`).add({
        name: name,
        image: image ? image : "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: description,
        projectId: projectId,
        programmeId: programmeId,
        classroomId: classroomId,
        participantsIds: [],
        messagesIds: [],
        board: board
      }).then((res) => {
        this.$firestore.doc(`projects/${projectId}`).update({
          subjectsIds: arrayUnion(res.id)
        })
        this.$firestore.doc(`programmes/${programmeId}`).update({
          subjectsIds: arrayUnion(res.id)
        })
        this.$firestore.doc(`subjects/${res.id}`).update({id:res.id}).then(resolve).catch(reject)
      }).catch(reject)
    })
  }

  public updateSubject(id, name, image, description, programmeId, classroomId, board){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`subjects/${id}`).update({
        name: name,
        image: image ? image : "https://firebasestorage.googleapis.com/v0/b/educa-1a6a5.appspot.com/o/default.png?alt=media&token=da897c0d-a809-46bb-af0f-05336336a3e8",
        description: description,
        programmeId: programmeId,
        classroomId: classroomId,
        board
      }).then(resolve).catch(reject)
    })
  }
  public updateSubjectParticipants(id, participantsIds, toPing, subject){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`subjects/${id}`).update({
        participantsIds: participantsIds
      }).then(() => {
        resolve(true);
        // if(!toPing.length) 
        toPing.forEach(participant => {
          console.log("SENDING EMAIL")
          this.$functions.httpsCallable('sendEmail')({
            recipient: participant.email,
            subject: 'Dodani ste na predmet na Educa platformi!',
            message: `Vašem korisničkom računu omogućen je pristup predmetu: ${subject.name}`
          }).toPromise().then(resolve)
        });
      }).catch(reject)
      
    })
  }

  public deleteSubject(subject){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`projects/${subject.projectId}`).update({subjectsIds: arrayRemove(subject.id)})
      this.$firestore.doc(`subjects/${subject.id}`).delete().then(resolve).catch(reject)
      this.events.getValue().forEach(e => {
        if(e.subjectId = subject.id) this.$firestore.doc(`events/${e.id}`).delete();
      })
    })
  }

  public createAsset(name, type, link, google, subjectId){
    return new Promise((resolve, reject) => {
      this.$firestore.collection(`assets`).add({
        time: new Date(),
        type: type,
        name: name,
        link,
        subjectId,
        google
      }).then(res => {
        this.$firestore.doc(`assets/${res.id}`).update({
          id: res.id
        }).then(resolve)
      })
    })
  }

  public updateAsset(id, name, link){
    return this.$firestore.doc(`assets/${id}`).update({name, link})
  }

  public createEvent(name, description, start, end, type, classroomId, subjectId, projectId){
    return new Promise((resolve, reject) => {
      this.$firestore.collection(`events`).add({
        name: name,
        description: description,
        start: start,
        end: end,
        type: type,
        classroomId,
        subjectId,
        projectId,
      }).then((res) => {
        this.$firestore.doc(`events/${res.id}`).update({id:res.id}).then(resolve).catch(reject)
      }).catch(reject)
    })
  }

  public deleteAsset(id){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`assets/${id}`).delete().then(resolve).catch(reject)
    })
  }

  public deleteEvent(id){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`events/${id}`).delete().then(resolve).catch(reject)
    })
  }

  public updateEvent(id, name, description, start, end, type, classroomId){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`events/${id}`).update({
        name: name,
        description: description,
        start: start,
        end: end,
        type: type,
        classroomId,
      }).then(resolve).catch(reject)
    })
  }

  
  public createNotification(text, link, participantId){
    return new Promise((resolve, reject) => {
      this.$firestore.collection(`notifications`).add({
        time: new Date(),
        text,
        link,
        participantId,
        seen: false
      }).then(res => {
        this.$firestore.doc(`notifications/${res.id}`).update({
          id: res.id
        }).then(() => {
          this.$functions.httpsCallable('sendEmail')({
            recipient: this.participants.getValue().find(p => p.id == participantId).email,
            subject: 'Nova obavijest na Educa Platformi!',
            message: text
          }).toPromise().then(resolve, reject)
        })
      })
    })
  }

  public seeNotification(id){
    return new Promise((resolve, reject) => {
      this.$firestore.doc(`notifications/${id}`).update({seen: true}).then(resolve)
    })
  }

  public seeMessage(id, pid = undefined){
    return new Promise((resolve, reject) => {
      let upd = {seen: true} as any
      if(pid) upd.seens = arrayUnion(pid);
      this.$firestore.doc(`messages/${id}`).update(upd).then(resolve)
    })
  }

  public createMessage(text, participant, recipientId, forum = false){
    return new Promise((resolve, reject) => {
      let link = `/app/participants/${participant.projectId}?participantId=${participant.id}`
      this.$firestore.collection(`messages`).add({
        time: new Date(),
        text,
        participantId : participant.id,
        participant,
        recipientId,
        seen: false,
        seens: []
      }).then(res => {
        this.$firestore.doc(`messages/${res.id}`).update({
          id: res.id
        }).then(() => {
          if(!forum){
            this.createNotification('Nova poruka od korisnika: ' + participant.name + ': ' + text + '\n',link, recipientId)
            resolve(true);
          } else {
            let subject = this.subjects.getValue().find(p => p.id == recipientId)
            subject.participantsIds.forEach(r => this.createNotification('Nova poruka u predmetu:' + subject.name + '\n','', r))
            resolve(true);
          }
        })
      })
    })
  }

  pushFileToStorage(fileUpload, name) {
    return new Promise((resolve, reject) => {
      const filePath = `/${name}`;
      const storageRef = this.$storage.ref(filePath);
      const uploadTask = this.$storage.upload(filePath, fileUpload);
    
      uploadTask.snapshotChanges().pipe(
        finalize(() => {
          storageRef.getDownloadURL().subscribe(downloadURL => {
            resolve(downloadURL)
          });
        })
      ).subscribe();
    })
  }
}
