<app-banner ></app-banner>
<ng-container *ngIf="loaded && subject">

    <div style="display: flex; flex-direction: row; margin-bottom: 20px;" class="controls"  *ngIf="($auth.participant | async).role != 'participant'">
        <button mat-raised-button color="accent" (click)="openSubject()"><mat-icon>edit</mat-icon> PREDMET</button>
        <button mat-raised-button color="accent" (click)="openContent()"><mat-icon>file_copy</mat-icon> MATERIJALI</button>
        <button mat-raised-button color="accent" (click)="openParticipants()"><mat-icon>people</mat-icon> SUDIONICI</button>
        <button mat-raised-button color="accent" (click)="openCalendar()"><mat-icon>calendar_month</mat-icon> KALENDAR</button>

        <!-- <button mat-raised-button color="accent"><mat-icon>grade</mat-icon> OCJENE</button> -->
    </div>
    

    
    <div class="room" style="display: grid; grid-template-columns: 4fr 2fr; grid-row-gap: 10px; grid-column-gap: 20px; margin-bottom: 20px;" *ngIf="($auth.selectedProject | async)!.id != 'avGKV8FFogtT6JfuOHuu'">
        <mat-card class="event" [ngClass]="{'inactive' : !event}" >
            <p class="event-first">NADOLAZEĆE PREDAVANJE:</p>
            <ng-container *ngIf="!event">
                <p class="event-second">HH:MM DD/MM/YYYY</p>
                <p class="event-third">Nema nadolazećeg predavanja...</p>
            </ng-container>
            <ng-container *ngIf="event">
                <p class="event-second">{{event.start.toDate() | date:'dd/MM/YYYY'}}</p>
                <p class="event-second-bot">{{event.start.toDate() | date:'HH:mm'}} - {{event.end.toDate() | date:'HH:mm'}}</p>
                <p class="event-third">{{event.name.toUpperCase()}}</p>
                <p class="event-fourth">{{event.description}}</p>
            </ng-container>
        </mat-card>
        <button mat-raised-button color="accent" style="grid-row: 2; grid-column: 1;" (click)="openPastEvents()"><mat-icon>checklist</mat-icon> PROŠLI DOGAĐAJI</button>
        <mat-card class="classroom" (click)="openClassroom()" *ngIf="classroom" style="grid-row: 1 / span 2;">
            <mat-icon>meeting_room</mat-icon>
            <p></p>
            <h3><small>PREDAVAONICA:</small><br>{{classroom!.name}}</h3>
        </mat-card>
    </div>
    
    <mat-card style="margin-bottom: 20px;" [innerHTML]="subject.board ? subject.board : 'No content...'">
    </mat-card>
    
    <div style="display: flex; flex-direction: row; flex-wrap: wrap;" >
        <mat-card class="asset" *ngFor="let asset of assets" (click)="openAsset(asset)">
            <div class="asset-icon">
                <mat-icon *ngIf="asset.type == 'doc'">description</mat-icon>
                <mat-icon *ngIf="asset.type == 'form'">quiz</mat-icon>
                <mat-icon *ngIf="asset.type == 'slide'">slideshow</mat-icon>
                <mat-icon *ngIf="asset.type == 'sheet'">table</mat-icon>
                <mat-icon *ngIf="asset.type == 'link'">link</mat-icon>
                <mat-icon *ngIf="asset.type == 'file'">folder</mat-icon>
                <mat-icon *ngIf="asset.type == 'video'">play_arrow</mat-icon>
            </div>
            <p >{{(asset.type == 'doc') ? 'Google Doc' : asset.type == 'sheet' ? 'Google Sheet':  asset.type == 'slide' ? 'Google Slides': asset.type == 'form' ? 'Google Form': asset.type == 'link' ? 'Poveznica' : asset.type == 'file' ? 'Datoteka' : asset.type == 'video' ? 'Video' : 'Ostalo'}}</p>
            <h3>{{asset.name}}</h3>
        </mat-card>
</div>
    <mat-card style="display: flex; flex-direction: column; margin-bottom: 20px;" *ngIf="($auth.participant | async)!.id != 'anonymous'">
        <h3>FORUM:</h3>
    
        <div class="message" *ngFor="let message of messages"  [ngClass]="{'message-mine' : message.participantId == $auth.participant.getValue().id}">
            <div class="message-image"><img [src]="message.participant!.image"><div class="status" [ngClass]="{'online':message.participant.status == 'online','away':message.participant.status == 'away'}"></div></div>
            <div class="message-name">{{message.participant!.name}}</div>
            <div class="message-title">{{message.participant!.title}}</div>
            <div  style="grid-row: 1 / span 3;"> <div class="message-text" [matTooltip]="message.seens.join(', ')"><span class="message-time">{{message.time.toDate().toLocaleString()}}<br></span>{{message.text}}<mat-icon class="message-read" *ngIf="message.seen">check</mat-icon></div></div>
           
        </div>
    
        <mat-form-field appearance="outline" style="width: 100%;">
            <mat-label>Unesi poruku...</mat-label>
            <input matInput type="text" [(ngModel)]="messageText"/>
            <mat-icon matSuffix color="accent" (click)="sendMessage()">send</mat-icon>
        </mat-form-field>
    </mat-card>

    <div style="display: flex; flex-direction: row; white-space: nowrap; flex-wrap: wrap;">
        <mat-card  class="participant-card" *ngFor="let p of participants">
            <div class="participant-card-image"><img [src]="p.image"><div class="status" [ngClass]="{'online':p.status == 'online','away':p.status == 'away'}"></div></div>
            <div class="participant-card-name">{{p.name}}</div>
            <div class="participant-card-title">{{p.title}}</div>
            <div class="participant-card-link">{{p.contactLocation}}</div>
            <div class="participant-card-chat">
                <button mat-raised-button color="accent" class="short-card-button" (click)="openChat(p)"><mat-icon>chat</mat-icon></button>
            </div>
        </mat-card>
    </div>
</ng-container>
