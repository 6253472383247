import { Component, OnInit, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { combineLatestWith, debounceTime, map, shareReplay, withLatestFrom } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { AuthService } from '../auth.service';
import { Event, Notification, Participant, Project, Subject } from 'models';
import { DatabaseService } from '../database.service';
import { LoaderService } from '../loader.service';
import { trigger, transition, style, animate,query } from '@angular/animations'
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  animations: [
    // trigger('fadeAnimation', [
    //   transition('* => *', [
    //     // query(':enter', [style({ opacity: 0, position: 'absolute' })], {
    //     //   optional: true,
    //     // }),
    //     // query(
    //     //   ':leave',
    //     //   [
    //     //     style({ opacity: 0 }),
    //     //     animate('2s', style({ opacity: 0, position: 'absolute' })),
    //     //   ],
    //     //   { optional: true }
    //     // ),
    //     query(
    //       ':enter',
    //       [
    //         style({ opacity: 0 }),
    //         animate('1s', style({ opacity: 1, position: 'relative' })),
    //       ],
    //       { optional: true }
    //     ),
    //   ]),
    // ])
  ]
})
export class NavigationComponent implements OnInit, OnDestroy {

  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(min-width: 1500px)').pipe(map(result => result.matches), shareReplay());

  constructor(
    private breakpointObserver: BreakpointObserver, 
    public $route : ActivatedRoute,
    public $router : Router,
    public $auth : AuthService,
    public $database : DatabaseService,
    public $loader : LoaderService,
    public $firestore : AngularFirestore
    ) {
      this.$route.url.subscribe(url => {
        this.url = url.join('/');
        console.log(this.url)
      })
    }


    public url : string = ''
    public projects : Project[] = [];
    public subjects : Subject[] = [];
    public participant : Participant | undefined;

    subs : Subscription[] = []
    ngOnDestroy(): void {
      
      this.subs.forEach(s => s.unsubscribe())
    }

    async ngOnInit(){

      this.$loader.open();
      console.log("loading...")
      await new Promise((resolve, reject) => {
      let sub; sub = this.$auth.loaded.subscribe((loaded) => {
        console.log("checking")



       
        if(loaded) { resolve(true); this.$loader.close(); }
      })
    })

      await new Promise((resolve, reject) => {
        this.subs.push(this.$auth.participant.subscribe(participant => {
          this.participant = participant

          if(!this.$auth.selectedProject.getValue() && this.$auth.participant.getValue().id == 'anonymous') {
            this.$router.navigate(['login'])
          } else {
            if(this.$auth.selectedProject.getValue() && this.$auth.participant.getValue().id == 'anonymous' && !this.$auth.selectedProject.getValue().public){
            
              this.$router.navigate(['login'])
            }
          }

          if(this.participant) resolve(true);
        }))
      })  

      this.subs.push(this.$database.projects.subscribe(projects => {
        if(this.participant?.id) {
          if(this.participant.role == 'admin')
            this.projects = projects
          else 
            this.projects = projects.filter(p => p.participantsIds.includes(this.participant!.id))
        }
      }))

      this.subs.push(this.$database.subjects.pipe(combineLatestWith(this.$auth.selectedProject)).subscribe(([subjects, project]) => {
        if(this.participant?.id && project
          
          ) {
          if(this.participant.role == 'admin' || this.participant.id == 'anonymous')
            this.subjects = subjects.filter(s => s.projectId == project.id)
          else 
            this.subjects = subjects.filter(s => s.projectId == project.id).filter(p => p.participantsIds.includes(this.participant!.id))
        }
      }))

      this.notifications = this.$database.notifications.getValue().filter(n => n.participantId == this.$auth.participant.getValue().id).sort((a, b) => a.time > b.time ? 1 : -1).slice(0, 3);
    
      this.subs.push(this.$auth.loaded.subscribe(loaded => {
        if(!loaded) return;
  
        // let subjectId = this.$auth.selectedSubject.getValue().id
        this.subs.push(this.$database.events.pipe(withLatestFrom(this.$auth.selectedProject, this.$auth.selectedSubject)).subscribe(([events, project, subject]) => {
          let projectId = project && project.id
          let subjectId = subject && subject.id
          console.log(projectId)
          this.allEvents = events.filter(e => !projectId || (e.projectId == projectId)).map(e => {
            if(e.subjectId) e.subjectName = this.$database.subjects.getValue().find(s => s.id == e.subjectId)?.name
            return e;
          })
          this.allEvents = this.allEvents.filter(e => !subjectId || (e.subjectId == subjectId))
          this.calendarEvents = this.allEvents.map(this.$database.eventToCalendarEvent)
          console.log(this.calendarEvents)
          this.updateEvents()
        }))
      }))


      let mousemove = new BehaviorSubject(0);
      addEventListener("mousemove", (event) => {
        mousemove.next(0)
      });

      mousemove.pipe(debounceTime(3000)).subscribe(res => {
        this.$firestore.doc(`participants/${this.participant.id}`).update({lastActive: new Date(new Date().getTime() + 30000)})
      })
    }

    notifications : Notification[] = [];
    open(notification : Notification){
      // this.$auth.selectedProject.next(this.$database.projects.getValue().find(p => p.id == notification.link.split('/').at(-1).split('=')[0]))

      this.$database.seeNotification(notification.id)
      console.log(notification.link)
      this.$router.navigateByUrl(notification.link)
    }

    logout(){
      this.$router.navigate(['login']);
      this.$auth.logout();
    }

    updateEvents(){
      let firstOfMonth = new Date(this.viewDate);
      firstOfMonth.setMonth(firstOfMonth.getMonth(), 1)
      firstOfMonth.setHours(0,0,0,0);
      let lastOfMonth = new Date(this.viewDate);
      lastOfMonth.setMonth(lastOfMonth.getMonth() + 1, 0)
      lastOfMonth.setHours(23,59,59,999);
      this.events = this.allEvents.filter(e => e.start.toDate() > this.viewDate && e.start.toDate() < lastOfMonth)
      
    }
  
  events: Event[] = [];
  allEvents: Event[] = [];
  calendarEvents : CalendarEvent[] = [];
  viewDate: Date = new Date()
  selectedDay = undefined;

  toggleDayHighlight(event, select){
    console.log(event.day)
    if(this.allEvents.some(e => e.start.toDate().getDate() == event.day.date.getDate())){
      this.selectedDay = event.day.date;
      this.viewDate = event.day.date
    } else {
      this.selectedDay = undefined;
      this.viewDate = new Date();
    }
    
    
    this.updateEvents();
  }

  now = new Date();

  link(event){
    console.log(event)
    this.$auth.selectedSubject.next(this.$database.subjects.getValue().find(s => s.id == event.subjectId))
    this.$auth.selectedProject.next(this.$database.projects.getValue().find(s => s.id == event.projectId))
    this.$router.navigate(['app/subject',event.projectId,event.subjectId])
  }


}
