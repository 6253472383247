<div class="container">

    <p *ngIf="!assets.length">Nema dodanih materijala...</p>

    <div style="margin-bottom: 40px; display: grid; grid-template-columns: 1fr; grid-column-gap: 20px; grid-row-gap: 20px;">
      <mat-card *ngFor="let asset of assets" style="display: grid; grid-template-columns: 3fr 1fr auto; grid-column-gap: 10px;">
        <mat-form-field appearance="outline">
            <mat-label>Naziv</mat-label>
            <input matInput [(ngModel)]="asset.name" type="text" />
        </mat-form-field>

        <!-- <mat-chip-list style="margin-bottom: 8px;">
            <mat-chip color="accent" [selected]="asset.type == 'doc'" (click)="asset.type = 'doc'; asset.link = 'https://docs.google.com/document/d/1XADGaMMMB5lVFT3BOweRt6u4oxoeDz741tHq3hEDg-w/edit?usp=sharing'">Google Document</mat-chip>
            <mat-chip color="accent" [selected]="asset.type == 'form'" (click)="asset.type = 'form'; asset.link='https://docs.google.com/forms/d/1ohosAA5La1KhFJS2Ju1IIDYyPs-Vyx2FKtRq2SFxx0s'">Google Forms</mat-chip>
            <mat-chip color="accent" [selected]="asset.type == 'slide'" (click)="asset.type = 'slide'; asset.link='https://docs.google.com/presentation/d/1-s7n1iY_o2rVUoHKoDpllrqJzLP0IzaV5WIBIXQTqyU'">Google SLides</mat-chip>
          </mat-chip-list> -->

        <mat-form-field appearance="outline">
            <mat-label>Poveznica</mat-label>
            <input matInput type="text" name="image" required [(ngModel)]="asset.link" [disabled]="!(asset.type == 'link' || asset.type == 'video')" disabled>
            <button *ngIf="asset.type == 'file'" mat-mini-fab matSuffix color="accent" (click)="fileUpload.click()"><mat-icon>attachment</mat-icon></button>
            <input type="file" class="file-input" style="display: none;"
           (change)="onFileSelected($event, asset)" #fileUpload>
        </mat-form-field>

        <button color="accent" mat-raised-button (click)="delete(asset)"><mat-icon>delete</mat-icon></button>
    </mat-card> 
    </div>
    

    <div style="display: grid; grid-template-columns: 1fr auto; grid-column-gap: 20px;">
        <mat-form-field appearance="outline">
            <mat-label>Vrsta materijala</mat-label>
            <mat-select [(ngModel)]="chosenAssetType">
              <mat-option  [value]="'doc'">Dokument (Google Doc)</mat-option>
              <mat-option  [value]="'sheet'">Tablica (Google Sheet)</mat-option>
              <mat-option  [value]="'form'">Upitnik (Google Form)</mat-option>
              <mat-option  [value]="'slide'">Prezentacija (Google Slides)</mat-option>
              <mat-option  [value]="'link'">Poveznica (link)</mat-option>
              <mat-option  [value]="'file'">Datoteka (file)</mat-option>
              <mat-option  [value]="'video'">Video (link)</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button extended color="accent" (click)="add()">DODAJ MATERIJAL <mat-icon>add</mat-icon></button>
    </div>
   
   
   
    <button mat-raised-button color="accent" style="margin-top: 40px;" (click)="save()">SPREMI PROMJENE</button>
</div>